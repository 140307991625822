import React from "react";
import { View } from "react-native";
import {
  TopNavigation,
  Layout,
  Divider,
  Button,
  Text,
  Icon,
  Radio,
  RadioGroup,
  Input,
} from "@ui-kitten/components";
import { useNavigation } from "@react-navigation/native";
import i18n from "~/src/i18n";
import ThingsboardService from "~/src/services/ThingsboardService";

export default function UserSettings() {
  const navigation = useNavigation();
  const userDetails = ThingsboardService.getUserDetails();

  function logout() {
    ThingsboardService.saveCredentials(null, null);
    navigation.replace("Login");
  }

  return (
    <>
      <Layout level="2">
        <View
          style={{
            flex: 1,
            paddingVertical: 10,
            paddingHorizontal: 20,
            flexDirection: "row",
          }}>
          <Text category="s1">{i18n.t("profile")}</Text>
        </View>
      </Layout>
      <Divider />
      <View
        style={{
          flex: 1,
          padding: 20,
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Icon style={{ width: 50, height: 50, marginRight: 20 }} name="user" />
        <View style={{ flex: 1 }}>
          <Text category="h5" style={{ marginBottom: 4 }}>
            {userDetails.firstName + " " + userDetails.lastName}
          </Text>
          <Text category="s1" style={{}}>
            {userDetails.sub}
          </Text>
        </View>
        <View>
          <Button appearance="outline" onPress={() => logout()}>
            {i18n.t("log_out")}
          </Button>
        </View>
      </View>
    </>
  );
}
