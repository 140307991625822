import React from "react";
import {
  Platform,
  TouchableHighlight as RNTouchableHighlight,
} from "react-native";
import {
  TouchableHighlight,
  TouchableNativeFeedback,
} from "react-native-gesture-handler";

export const TouchableNative = (props) => {
  if (Platform.OS === "android") {
    return (
      <TouchableNativeFeedback {...props}>
        {props.children}
      </TouchableNativeFeedback>
    );
  } else if (Platform.OS === "ios") {
    return (
      <TouchableHighlight underlayColor={"#55555555"} {...props}>
        {props.children}
      </TouchableHighlight>
    );
  } else {
    return (
      <RNTouchableHighlight underlayColor={"#55555555"} {...props}>
        {props.children}
      </RNTouchableHighlight>
    );
  }
};

export default TouchableNative;
