import React from "react";
import { TopNavigationAction, Icon } from "@ui-kitten/components";

export function renderBackAction(navigation) {
  if (!navigation.canGoBack()) {
    return null;
  }
  return (
    <TopNavigationAction
      icon={(props) => <Icon name="chevron-left" {...props} />}
      onPress={() => navigation.goBack()}
    />
  );
}

export default renderBackAction;
