import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import {
  Image,
  View,
  FlatList,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import {
  TopNavigation,
  TopNavigationAction,
  Icon,
  Layout,
  Button,
  Text,
  ButtonGroup,
  Divider,
  useTheme,
} from "@ui-kitten/components";
import { useNavigation } from "@react-navigation/native";
import { Svg, Path } from "react-native-svg";
import * as Notifications from "expo-notifications";

import ScoreService from "../services/ScoreService";
import LevelsService from "../services/LevelsService";
import colors from "../../config/colors";
import useReadingsStore from "../stores/ReadingsStore";
import TouchableNative from "../utils/TouchableNative";
import ThingsboardService from "../services/ThingsboardService";
import PushService from "../services/PushService";
import i18n from "../i18n";
import LayoutSafeArea from "../ui/LayoutSafeArea";
import Slider from "../ui/Slider";

export function ReadingsList({ readings, deviceId, style: styleProp }) {
  const navigation = useNavigation();
  function renderRow(item) {
    return (
      <TouchableNative
        key={item.type}
        onPress={() =>
          navigation.navigate("Detail", { type: item.type, deviceId })
        }>
        <View
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 20,
            paddingTop: 20,
          }}>
          <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}>
            {item.title}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: LevelsService.getRangeColor(item.category),
            }}>
            {item.value} {item.unit} / {i18n.t(item.category).toLowerCase()}
          </Text>
        </View>
      </TouchableNative>
    );
  }
  return (
    <View style={{ ...styleProp }}>
      {readings.map((item) => renderRow(item))}
    </View>
  );
}

export default function HomeScreen({ navigation, route }) {
  const theme = useTheme();

  const [deviceId, setDeviceId] = useState(null);
  const [reloadEnabled, setReloadEnabled] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [lamp, setLamp] = useState(null);
  const [lampBrightness, setLampBrightness] = useState(null);
  const { readings, setReadings } = useReadingsStore();
  const score = ScoreService.calculateScore(readings);

  async function loadDevices() {
    try {
      const data = await ThingsboardService.getDevices();
      if (deviceId == null) {
        setDeviceId(data[0].id.id);
      }
    } catch (error) {
      console.log(error);
      setConnectionError(true);
    }
  }

  async function loadReadings(deviceId) {
    setConnectionError(false);
    try {
      let data = await ThingsboardService.getReadings(deviceId);
      setReadings(data);
      setConnectionError(false);
      setReloadEnabled(true);
    } catch (error) {
      console.log(error);
      setConnectionError(true);
      return;
    }
    try {
      let data = await ThingsboardService.getAttributes(deviceId);
      console.log(data);
      data.filter((it) => it.key == "light").forEach((it) => setLamp(it.value));
      data
        .filter((it) => it.key == "brightness")
        .forEach((it) => setLampBrightness(it.value));
    } catch (error) {
      console.log(error);
      return;
    }
  }

  async function setLight(mode) {
    setLamp(mode);
    await ThingsboardService.setAttributes(deviceId, { light: mode });
  }
  async function setLightBrightness(brightness) {
    await ThingsboardService.setAttributes(deviceId, { brightness });
  }

  useEffect(() => {
    setReloadEnabled(false);
    setReadings([]);
    loadDevices();
  }, []);
  useEffect(() => {
    if (deviceId != null) {
      setReloadEnabled(false);
      setReadings([]);
      loadReadings(deviceId);
    }
  }, [deviceId]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (reloadEnabled && deviceId != null) {
        loadReadings(deviceId);
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [reloadEnabled]);

  const responseListener = useRef();
  useEffect(() => {
    PushService.registerForPushNotifications();
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );
    return () => {
      Notifications.removeNotificationSubscription(responseListener);
    };
  }, []);

  return (
    <LayoutSafeArea level="1" ignoreBottom>
      <TopNavigation
        title="Luftio"
        alignment="center"
        accessoryRight={() => (
          <TopNavigationAction
            icon={(props) => <Icon name="settings" {...props} />}
            onPress={() => navigation.navigate("Settings")}
          />
        )}
      />
      <Divider />
      <ScrollView>
        <Layout level="2">
          <View
            style={{
              flexDirection: "column",
              paddingTop: 15,
              alignItems: "center",
            }}>
            <View>
              <Svg width="170" height="170" viewBox="0 0 24 24">
                <Path
                  d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
                  fill={
                    score
                      ? LevelsService.getRangeColor(score.scoreCategory)
                      : colors.LEVEL_GOOD
                  }
                />
              </Svg>
              <View
                style={{
                  width: 170,
                  height: 110,
                  flexDirection: "row",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}>
                {connectionError && (
                  <Text
                    style={{
                      fontSize: 50,
                      fontWeight: "bold",
                    }}>
                    X
                  </Text>
                )}
                {score && !connectionError && (
                  <>
                    <Text
                      style={{
                        fontSize: 50,
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      {score.score}
                    </Text>
                    <Text
                      style={{ fontSize: 30, color: "white", marginBottom: 5 }}>
                      %
                    </Text>
                  </>
                )}
                {!score && !connectionError && (
                  <ActivityIndicator
                    size="large"
                    color="#ffffff"
                    style={{ marginBottom: 10 }}
                  />
                )}
              </View>
            </View>
            <Text
              category="h6"
              style={{
                marginTop: 10,
                marginBottom: 35,
                textAlign: "center",
                color: score
                  ? LevelsService.getRangeColor(score.scoreCategory)
                  : null,
              }}>
              {score
                ? i18n.t(score.scoreCategory)
                : connectionError
                ? i18n.t("connection_error")
                : i18n.t("loading")}
            </Text>
          </View>
          {lamp != null && (
            <View
              style={{
                marginLeft: 30,
                marginRight: 30,
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <Button
                onPress={() => setLight("off")}
                appearance={lamp == "off" ? "filled" : "outline"}>
                {i18n.t("off")}
              </Button>
              <Button
                onPress={() => setLight("standard")}
                appearance={lamp == "standard" ? "filled" : "outline"}>
                {i18n.t("colors")}
              </Button>
              <Button
                onPress={() => setLight("lamp")}
                appearance={lamp == "lamp" ? "filled" : "outline"}>
                {i18n.t("lamp")}
              </Button>
            </View>
          )}
          {lampBrightness != null && (
            <View
              style={{
                marginLeft: 20,
                marginRight: 30,
                marginTop: 10,
                marginBottom: 10,
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Icon
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  tintColor: theme["color-primary-default"],
                }}
                name={lampBrightness < 127 ? "sun-outline" : "sun"}
                pack="eva"
              />
              <Slider
                style={{ flex: 1 }}
                minimumValue={0}
                maximumValue={255}
                step={1}
                value={lampBrightness}
                onValueChange={(value) => setLampBrightness(value)}
                onSlidingComplete={() =>
                  setLightBrightness(Math.floor(lampBrightness))
                }
              />
            </View>
          )}
        </Layout>
        <Divider />

        {connectionError && (
          <View
            style={{
              padding: 20,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Text
              category="p1"
              style={{ marginBottom: 24, textAlign: "center" }}>
              {i18n.t("msg_connection_error")}
            </Text>
            <Button onClick={() => loadDevices()}>{i18n.t("refresh")}</Button>
          </View>
        )}
        <ReadingsList readings={readings} deviceId={deviceId} />
      </ScrollView>
    </LayoutSafeArea>
  );
}
