import React from "react";
import { View, Modal as ReactModal, Platform } from "react-native";
import { Card } from "@ui-kitten/components";

import WebModal from "modal-enhanced-react-native-web";

export default function Modal({ children, visible, onClose }) {
  if (Platform.OS === "web") {
    return (
      <WebModal isVisible={visible}>
        <Card style={{ maxWidth: "400px", margin: "auto" }}>{children}</Card>
      </WebModal>
    );
  }
  return (
    <ReactModal
      visible={visible}
      transparent={true}
      onRequestClose={() => onClose()}
      animationType="fade">
      <View
        style={{
          flex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Card style={{ margin: 20 }}>{children}</Card>
      </View>
    </ReactModal>
  );
}
