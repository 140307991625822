import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { AppearanceProvider, useColorScheme } from "react-native-appearance";
import "react-native-gesture-handler";
import AppLoading from "expo-app-loading";

import * as eva from "@eva-design/eva";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { StatusBar } from "expo-status-bar";

import {
  HomeScreen,
  DetailScreen,
  SettingsScreen,
  LoginScreen,
  RegisterScreen,
  ForgottenPasswordScreen,
} from "./src/screens";
import ThingsboardService from "./src/services/ThingsboardService";
import FeatherIconsPack from "./src/ui/FeatherIconsPack";
import { EvaIconsPack } from "@ui-kitten/eva-icons";

import { default as theme } from "./config/theme.json";
import { default as mapping } from "./config/mapping.json";

import * as Sentry from "sentry-expo";

Sentry.init({
  dsn:
    "https://10abd7b840a14a3aa7fcafba9e31a87d@o550006.ingest.sentry.io/5689201",
  enableInExpoDevelopment: true,
  debug: false,
});

const Stack = createStackNavigator();

export function App() {
  let colorScheme = useColorScheme();
  const [isReady, setReady] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  async function loadApp() {
    // Check login
    try {
      const {
        username,
        password,
      } = await ThingsboardService.getSavedCredentials();
      await ThingsboardService.login(username, password);
      setLoggedIn(true);
    } catch (error) {
      console.log(error);
      setLoggedIn(false);
      return;
    }
  }
  if (!isReady) {
    return (
      <AppLoading
        startAsync={loadApp}
        onFinish={() => setReady(true)}
        onError={console.error}
      />
    );
  }
  let firstScreen;
  if (!isLoggedIn) {
    firstScreen = "Login";
  } else {
    firstScreen = "Home";
  }
  return (
    <>
      <StatusBar style="dark" />
      <IconRegistry
        icons={[FeatherIconsPack, EvaIconsPack]}
        defaultIcons="feather"
      />
      <ApplicationProvider
        {...eva}
        theme={{
          ...(colorScheme === "darkX" ? eva.dark : eva.light),
          ...theme,
        }}
        customMapping={mapping}>
        <NavigationContainer>
          <Stack.Navigator
            headerMode="none"
            screenOptions={{ animationEnabled: true, header: null }}>
            {[
              { name: "Home", component: HomeScreen },
              { name: "Detail", component: DetailScreen },
              { name: "Settings", component: SettingsScreen },
              { name: "Login", component: LoginScreen },
              { name: "Register", component: RegisterScreen },
              { name: "ForgottenPassword", component: ForgottenPasswordScreen },
            ]
              .sort((a, b) => {
                if (a.name == firstScreen) return -1;
                if (b.name == firstScreen) return 1;
                return 0;
              })
              .map((it) => (
                <Stack.Screen key={it.name} {...it} />
              ))}
          </Stack.Navigator>
        </NavigationContainer>
      </ApplicationProvider>
    </>
  );
}

export default () => (
  <AppearanceProvider>
    <App />
  </AppearanceProvider>
);
