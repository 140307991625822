import createStore from "zustand";
import i18n from "../i18n";
import LevelsService from "../services/LevelsService";

export default createStore((set) => ({
  readings: [],
  setReadings: (values) => {
    let readings = [];
    if (values["co2"]) {
      readings.push({
        type: "co2",
        title: i18n.t("co2"),
        value: values["co2"],
        unit: "ppm",
      });
    }
    if (values["temperature"]) {
      readings.push({
        type: "temp",
        title: i18n.t("temperature"),
        value: values["temperature"],
        unit: "°C",
      });
    }
    if (values["humidity"]) {
      readings.push({
        type: "humidity",
        title: i18n.t("humidity"),
        value: values["humidity"],
        unit: "%",
      });
    }
    if (values["pressure"]) {
      readings.push({
        type: "pressure",
        title: i18n.t("pressure"),
        value: values["pressure"],
        unit: "hPa",
      });
    }
    readings = readings.map((it) => ({
      ...it,
      category: LevelsService.getRange(it.type, it.value),
    }));
    set({ readings });
  },
}));
