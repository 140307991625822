import axios from "axios";
import jwt_decode from "jwt-decode";
import AsyncStorage from "@react-native-community/async-storage";

const THINGSBOARD_SERVER = "https://app.luftio.com/tb/";
const APP_BACKEND_SERVER = "https://app.luftio.com/backend/";

var token = "";
var refreshToken = "";

export const getAuthHeader = () => {
  return { "X-Authorization": "Bearer " + token };
};

export const getSavedCredentials = async () => {
  return JSON.parse(await AsyncStorage.getItem("auth_credentials")) || {};
};
export const saveCredentials = async (username, password) => {
  AsyncStorage.setItem(
    "auth_credentials",
    JSON.stringify({ username, password })
  );
};

export const getUserDetails = () => {
  var decoded = jwt_decode(token);
  return decoded;
};

export const login = async (username, password) => {
  const response = await axios.post(THINGSBOARD_SERVER + "api/auth/login", {
    username,
    password,
  });
  token = response.data.token;
  refreshToken = response.data.refreshToken;
};

export const register = async ({
  pairingCode,
  firstName,
  lastName,
  email,
  password,
}) => {
  const response = await axios.post(APP_BACKEND_SERVER + "account/register", {
    pairingCode,
    firstName,
    lastName,
    email,
    password,
  });
  token = response.data.token;
  refreshToken = response.data.refreshToken;
  return response;
};

export const getDevices = async () => {
  var userDetails = getUserDetails();
  var url = THINGSBOARD_SERVER;
  if (userDetails.scopes[0] != "TENANT_ADMIN") {
    url += "api/customer/" + userDetails.customerId;
  } else {
    url += "api/tenant";
  }
  url += "/devices?page=0&pageSize=100";
  const response = await axios.get(url, {
    headers: getAuthHeader(),
  });
  return response.data.data;
};

export const getUserProfile = async () => {
  const response = await axios.get(THINGSBOARD_SERVER + "api/auth/user", {
    headers: getAuthHeader(),
  });
  return response.data;
};

export const getReadings = async (deviceId) => {
  const response = await axios.get(
    THINGSBOARD_SERVER +
      "api/plugins/telemetry/DEVICE/" +
      deviceId +
      "/values/timeseries",
    {
      headers: getAuthHeader(),
    }
  );
  const data = response.data;
  return {
    co2: Math.round(data.co2[0].value),
    temperature: Math.round(data.temp[0].value * 10) / 10,
    pressure: Math.round(data.pres[0].value / 100),
    humidity: Math.round(data.hum[0].value * 10) / 10,
  };
};

export const getReadingsTimeseries = async (
  deviceId,
  keys,
  startTs,
  endTs,
  interval
) => {
  const response = await axios.get(
    THINGSBOARD_SERVER +
      `api/plugins/telemetry/DEVICE/${deviceId}/values/timeseries?keys=${keys}&startTs=${startTs}&endTs=${endTs}&interval=${interval}&agg=AVG`,
    {
      headers: getAuthHeader(),
    }
  );
  const data = response.data;
  return data;
};

export const getAttributes = async (deviceId) => {
  const response = await axios.get(
    THINGSBOARD_SERVER +
      "api/plugins/telemetry/DEVICE/" +
      deviceId +
      "/values/attributes",
    {
      headers: getAuthHeader(),
    }
  );
  return response.data;
};

export const setAttributes = async (deviceId, attributes) => {
  const response = await axios.post(
    THINGSBOARD_SERVER +
      "api/plugins/telemetry/DEVICE/" +
      deviceId +
      "/attributes/SHARED_SCOPE",
    { ...attributes },
    {
      headers: getAuthHeader(),
    }
  );
  return response.data;
};

export const resetPasswordByEmail = async (email) => {
  const response = await axios.post(
    THINGSBOARD_SERVER + "api/noauth/resetPasswordByEmail",
    { email }
  );
  return response.data;
};

export const updateToken = async (token) => {
  const response = await axios.post(
    APP_BACKEND_SERVER + "push/updateToken",
    {
      token,
    },
    {
      headers: getAuthHeader(),
    }
  );
  return response;
};

export default {
  login,
  register,
  getUserDetails,
  getUserProfile,
  getDevices,
  getReadings,
  getReadingsTimeseries,
  getAttributes,
  setAttributes,
  getSavedCredentials,
  saveCredentials,
  resetPasswordByEmail,
  updateToken,
};
