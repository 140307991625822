export function calculateScore(readings) {
  if (readings.length != 4) {
    return null;
  }
  let score = 0;
  readings.forEach((it) => {
    if (it.type == "co2") {
      let eq = 100 - (it.value - 500) * 0.075;
      score += Math.min(100, Math.max(0, eq)) * 4;
    } else if (it.type == "humidity") {
      let eq = 105 - Math.pow(45 - it.value, 2) * 0.1;
      score += Math.min(100, Math.max(0, eq)) * 2;
    } else if (it.type == "temp") {
      let eq = 105 - Math.pow(21 - it.value, 2);
      score += Math.min(100, Math.max(0, eq));
    }
  });
  score = Math.round(score / 7);

  let scoreCategory;
  if (score > 70) {
    scoreCategory = "good";
  } else if (score > 50) {
    scoreCategory = "bad";
  } else {
    scoreCategory = "terrible";
  }
  return { score, scoreCategory };
}

export default { calculateScore };
