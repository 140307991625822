import React, { useState, useEffect, useCallback } from "react";
import { View } from "react-native";
import {
  TopNavigation,
  Layout,
  Divider,
  Button,
  Text,
  Icon,
  Radio,
  RadioGroup,
  Input,
} from "@ui-kitten/components";

import i18n from "~/src/i18n";
import ThingsboardService from "~/src/services/ThingsboardService";

export default function NightModeSettings({ deviceId }) {
  const NIGHT_MODES = ["inactive", "off", "low"];
  const [nightMode, setNightMode] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  function validateTime(time) {
    if (time == null) return true;
    return time.match(/^\d{1,2}:\d{2}$/);
  }

  async function getSettings() {
    const attributesRes = await ThingsboardService.getAttributes(deviceId);
    let settings = attributesRes.find((it) => it.key == "nightmode_settings");
    if (settings) {
      try {
        return JSON.parse(settings.value);
      } catch (error) {
        console.error(error);
      }
    }
    return { mode: "inactive", start: "21:00", end: "7:00" };
  }

  function toUTCTime(time) {
    const date = new Date();
    date.setHours(+time.split(":")[0]);
    date.setMinutes(+time.split(":")[1]);
    return (
      date.getUTCHours() +
      ":" +
      (date.getUTCMinutes() < 10 ? "0" : "") +
      date.getUTCMinutes()
    );
  }
  function toLocalTime(time) {
    const date = new Date();
    console.log(time);
    date.setUTCHours(+time.split(":")[0]);
    date.setUTCMinutes(+time.split(":")[1]);
    console.log(date);
    return (
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  }

  const saveNightMode = useCallback(
    async (nightMode) => {
      const settings = await getSettings();
      const newSettings = {
        ...settings,
        mode: nightMode,
        start: toUTCTime(startTime),
        end: toUTCTime(endTime),
      };
      await ThingsboardService.setAttributes(deviceId, {
        nightmode_settings: JSON.stringify(newSettings),
      });
    },
    [nightMode, startTime, endTime]
  );

  async function loadData() {
    const settings = await getSettings();
    setNightMode(settings.mode);
    setStartTime(toLocalTime(settings.start));
    setEndTime(toLocalTime(settings.end));
  }

  useEffect(() => {
    loadData();
  }, [deviceId]);

  useEffect(() => {
    loadData();
  }, [deviceId]);

  return (
    <>
      <Layout level="2">
        <Text
          category="s1"
          style={{
            marginVertical: 10,
            marginHorizontal: 20,
          }}>
          {i18n.t("night_mode")}
        </Text>
      </Layout>
      <Divider />
      <View
        style={{
          flex: 1,
          padding: 20,
        }}>
        <RadioGroup
          selectedIndex={NIGHT_MODES.indexOf(nightMode)}
          onChange={(index) => {
            setNightMode(NIGHT_MODES[index]);
            saveNightMode(NIGHT_MODES[index]);
          }}>
          <Radio>{i18n.t("night_mode_inactive")}</Radio>
          <Radio>{i18n.t("night_mode_off")}</Radio>
          <Radio>{i18n.t("night_mode_low")}</Radio>
        </RadioGroup>
        <View style={{ flexDirection: "row", marginTop: 8 }}>
          <Input
            label={i18n.t("start_time")}
            value={startTime}
            status={validateTime(startTime) ? "basic" : "danger"}
            accessoryLeft={(props) => <Icon {...props} name="sunset" />}
            onChangeText={(text) => setStartTime(text)}
            onBlur={() => saveNightMode(nightMode)}
            style={{ flex: 1, marginRight: 10 }}
          />
          <Input
            label={i18n.t("end_time")}
            value={endTime}
            status={validateTime(endTime) ? "basic" : "danger"}
            accessoryLeft={(props) => <Icon {...props} name="sunrise" />}
            onChangeText={(text) => setEndTime(text)}
            onBlur={() => saveNightMode(nightMode)}
            style={{ flex: 1 }}
          />
        </View>
      </View>
    </>
  );
}
