import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import { View } from "react-native";
import {
  Layout,
  TopNavigation,
  Text,
  Button,
  Divider,
  Card,
  Calendar,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import { ScrollView, TapGestureHandler } from "react-native-gesture-handler";
import {
  StackedAreaChart,
  LineChart,
  XAxis,
  YAxis,
  Grid,
} from "react-native-svg-charts";

import useReadingsStore from "../stores/ReadingsStore";
import LevelsService from "../services/LevelsService";
import i18n from "../i18n";
import renderBackAction from "../utils/renderBackAction";
import LayoutSafeArea from "../ui/LayoutSafeArea";
import Modal from "../ui/Modal";
import ThingsboardService from "../services/ThingsboardService";

const useComponentSize = () => {
  const [size, setSize] = useState(null);

  const onLayout = useCallback((event) => {
    const { width, height } = event.nativeEvent.layout;
    setSize({ width, height });
  }, []);

  return [size, onLayout];
};

export function LevelVizualizer({ style: styleProp, data }) {
  const [size, onLayout] = useComponentSize();
  const { currentPosition, gradientPoints, labels } = useMemo(() => {
    const levels = LevelsService.levels[data.type];
    const currentPosition = Math.min(
      (data.value - levels.totalRange.from) /
        (levels.totalRange.to - levels.totalRange.from),
      1.03
    );
    const gradientPoints = [];
    for (let range of levels.ranges) {
      let color = LevelsService.getRangeColor(range.type);
      let x1 =
        (range.from - levels.totalRange.from) /
        (levels.totalRange.to - levels.totalRange.from);
      let x2 =
        (range.to - levels.totalRange.from) /
        (levels.totalRange.to - levels.totalRange.from);
      gradientPoints.push({ color, pos: x1 });
      gradientPoints.push({ color, pos: x2 });
    }
    const labels = levels.labels.map((it) => {
      return {
        value: it,
        pos:
          (it - levels.totalRange.from) /
          (levels.totalRange.to - levels.totalRange.from),
      };
    });
    return { levels, currentPosition, gradientPoints, labels };
  }, [data]);

  return (
    <View style={{ ...styleProp }}>
      <LinearGradient
        onLayout={onLayout}
        style={{
          width: "100%",
          height: 26,
          borderRadius: 15,
          marginBottom: 20,
        }}
        colors={gradientPoints.map((it) => it.color)}
        locations={gradientPoints.map((it) => it.pos)}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      />
      {size && (
        <View
          style={{
            width: 24,
            height: 24,
            position: "absolute",
            borderRadius: 12,
            backgroundColor: "white",
            top: 1,
            left: currentPosition * (size.width - 30) + 3,
          }}
        />
      )}
      {size &&
        labels.map((it) => (
          <Text
            key={it.value}
            style={{
              opacity: 0.7,
              position: "absolute",
              top: 35,
              left: Math.min(
                Math.max(it.pos * size.width - 10, 5),
                size.width - 30
              ),
            }}>
            {it.value}
          </Text>
        ))}
    </View>
  );
}

export function Chart({ type, deviceId, timeScale, customRange }) {
  const [data, setData] = useState([]);

  const axesSvg = { fontSize: 12, fill: "#333" };
  const axesYSvg = {
    fontSize: 14,
    fontWeight: "bold",
    fill: "#222",
    opacity: 0.7,
  };

  const typeMap = {
    co2: "co2",
    temp: "temp",
    humidity: "hum",
    pressure: "pres",
  };
  const timeScales = {
    "6h": [+(new Date() - 6 * 3600000), +new Date(), 900000, 6],
    day: [+(new Date() - 24 * 3600000), +new Date(), 1800000, 8],
    yesterday: [
      +(new Date() - 48 * 3600000),
      +(new Date() - 24 * 3600000),
      1800000,
      6,
    ],
    week: [+(new Date() - 7 * 24 * 3600000), +new Date(), 2 * 3600000, 7],
    month: [+(new Date() - 30 * 24 * 3600000), +new Date(), 24 * 3600000, 10],
    custom: [
      +new Date(+customRange),
      +new Date(+customRange) + 24 * 3600000,
      1800000,
      8,
    ],
  };
  let timing = timeScales[timeScale];
  async function loadData() {
    let result = await ThingsboardService.getReadingsTimeseries(
      deviceId,
      typeMap[type],
      timing[0],
      timing[1],
      timing[2]
    );
    var output = result[typeMap[type]] || [];
    if (type == "temp") {
      output = output.map((it) => ({ ...it, value: it.value }));
    } else if (type == "pressure") {
      output = output.map((it) => ({ ...it, value: it.value / 100 }));
    }
    setData(output);
  }
  useEffect(() => {
    loadData();
  }, [type, timeScale, customRange]);

  function formatDate(v) {
    let date = new Date(v);
    if (timeScale == "week" || timeScale == "month") {
      return date.getDate() + "/" + (date.getMonth() + 1);
    }
    return date.getHours() + "h";
  }
  let dateData = [timing[0], timing[1]];

  const ranges = LevelsService.levels[type];
  const xMin = timing[0];
  const xMax = timing[1];
  const yMin = ranges.totalRange.from;
  const yMax = ranges.totalRange.to;
  const rangeColors = [];
  const rangeKeys = [];
  const rangeHelper = {};
  ranges.ranges.forEach((it) => {
    rangeColors.push(LevelsService.getRangeColor(it.type));
    var newType = it.type;
    while (rangeKeys.indexOf(newType) >= 0) {
      newType += "x";
    }
    var figure = it.to;
    rangeKeys.forEach((key) => {
      figure -= rangeHelper[key];
    });
    rangeHelper[newType] = figure;
    rangeKeys.push(newType);
  });
  const rangeData = [
    {
      x: 0,
      ...rangeHelper,
    },
    {
      x: 1,
      ...rangeHelper,
    },
  ];

  return (
    <View style={{ height: 400 }}>
      <Divider />
      <View style={{ flex: 1 }}>
        <StackedAreaChart
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
          data={rangeData}
          keys={rangeKeys}
          colors={rangeColors}
          showGrid={true}
          yMin={yMin}
          yMax={yMax}>
          <Grid />
        </StackedAreaChart>
        <LineChart
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
          }}
          data={data}
          xAccessor={(value, index) => value.item.ts}
          xMin={xMin}
          xMax={xMax}
          yAccessor={(value, index) => Number(value.item.value)}
          yMin={yMin}
          yMax={yMax}
          clamp={true}
          contentInset={{ top: 5, bottom: 10 }}
          svg={{ stroke: "white", strokeWidth: 3 }}></LineChart>
        <YAxis
          style={{
            position: "absolute",
            top: 0,
            left: 5,
            bottom: 10,
            zIndex: 3,
          }}
          data={[yMin, yMax]}
          contentInset={{ top: 5, bottom: 10 }}
          svg={axesYSvg}
        />
      </View>
      <Divider />
      <XAxis
        style={{ marginTop: 10, height: 30 }}
        data={dateData}
        formatLabel={(value, index) => formatDate(value)}
        xAccessor={(value, index) => value.item}
        xMin={xMin}
        xMax={xMax}
        contentInset={{ left: 10, right: 10 }}
        numberOfTicks={timing[3]}
        svg={axesSvg}
      />
    </View>
  );
}

export default function DetailScreen({ navigation, route }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: data.title,
    });
  }, [navigation]);

  const { type, deviceId } = route.params;
  const readings = useReadingsStore((state) => state.readings);
  const data = readings.filter((it) => it.type == type)[0];
  const [chartScale, setChartScale] = useState("6h");
  const [customOpen, setCustomOpen] = useState(false);
  const [customRange, setCustomRange] = useState(null);

  return (
    <LayoutSafeArea>
      <TopNavigation
        title={data.title}
        alignment="center"
        accessoryLeft={() => renderBackAction(navigation)}
      />
      <ScrollView style={{ flex: 1 }}>
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingTop: 20,
              paddingBottom: 10,
              marginBottom: 20,
            }}>
            <Text style={{ fontSize: 50, fontWeight: "bold" }}>
              {data.value}
            </Text>
            <Text
              style={{
                fontSize: 30,
                marginLeft: 5,
                marginBottom: 5,
              }}>
              {data.unit}
            </Text>
          </View>
          <Text
            style={{
              marginLeft: 20,
              marginRight: 20,
              fontSize: 18,
              fontWeight: "bold",
              alignSelf: "center",
              color: LevelsService.getRangeColor(data.category),
            }}>
            {i18n.t("level")}: {i18n.t(data.category)}
          </Text>
          <LevelVizualizer
            data={data}
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginTop: 20,
              marginBottom: 30,
            }}
          />
          <Modal visible={customOpen} onClose={() => setCustomOpen(false)}>
            <Calendar
              date={customRange}
              onSelect={(date) => setCustomRange(date)}
              max={new Date()}
              style={{
                marginTop: -18,
                marginLeft: -26,
                marginRight: -26,
                marginBottom: 10,
              }}
            />
            <Button
              status="basic"
              onPress={() => {
                setCustomOpen(false);
                setChartScale("custom");
              }}>
              OK
            </Button>
          </Modal>
          <ScrollView horizontal={true}>
            <View
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10,
                marginBottom: 15,
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              {["6h", "day", "yesterday", "week", "month"].map((it) => (
                <Button
                  key={it}
                  style={{
                    marginRight: 8,
                  }}
                  onPress={() => setChartScale(it)}
                  appearance={chartScale == it ? "filled" : "outline"}>
                  {i18n.t("time_" + it)}
                </Button>
              ))}
              {customRange && (
                <Button
                  style={{
                    marginRight: 8,
                  }}
                  onPress={() => setChartScale("custom")}
                  appearance={chartScale == "custom" ? "filled" : "outline"}>
                  {customRange.getDate() + "/" + (customRange.getMonth() + 1)}
                </Button>
              )}
              <Button appearance="outline" onPress={() => setCustomOpen(true)}>
                {i18n.t("custom")}
              </Button>
            </View>
          </ScrollView>
          <Chart
            deviceId={deviceId}
            type={type}
            timeScale={chartScale}
            customRange={customRange}
          />
        </View>
      </ScrollView>
    </LayoutSafeArea>
  );
}
