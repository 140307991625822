import React, { useState, useCallback, useEffect } from "react";
import { View, Platform, StatusBar, KeyboardAvoidingView } from "react-native";
import {
  Text,
  Input,
  Button,
  TopNavigation,
  Divider,
  Layout,
  Card,
} from "@ui-kitten/components";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import ThingsboardService from "../services/ThingsboardService";
import i18n from "../i18n";
import LayoutSafeArea from "../ui/LayoutSafeArea";

export default function LoginScreen({ navigation }) {
  const [loginError, setLoginError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function login() {
    try {
      setLoginError(null);
      await ThingsboardService.login(email, password);
      ThingsboardService.saveCredentials(email, password);
      navigation.replace("Home");
    } catch (error) {
      console.log(error);
      console.log(error);
      if (error.response.status == 401) {
        setLoginError("login");
      } else {
        setLoginError("other");
      }
    }
  }

  async function demoLogin() {
    await ThingsboardService.login("demo@luftio.com", "demoluftio");
    ThingsboardService.saveCredentials("demo@luftio.com", "demoluftio");
    navigation.replace("Home");
  }

  useEffect(() => {
    if (
      Platform.OS == "web" &&
      window &&
      window.location &&
      window.location.hash == "#demologin"
    ) {
      demoLogin();
    }
  }, []);

  return (
    <LayoutSafeArea>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <View style={{ flex: 1, padding: 24 }}>
          <Text category="h1" style={{ marginTop: 32, marginBottom: 16 }}>
            {i18n.t("welcome_title")}
          </Text>
          <Text category="s1" style={{ marginBottom: 12 }}>
            {i18n.t("are_you_a_new_customer")}
          </Text>
          <Button
            style={{ marginBottom: 32 }}
            onPress={() => navigation.navigate("Register")}>
            {i18n.t("register")}
          </Button>
          <Text category="s1" style={{ marginBottom: 12 }}>
            {i18n.t("do_you_have_an_account_already")}
          </Text>
          <Input
            label={i18n.t("email")}
            value={email}
            onChangeText={(text) => setEmail(text)}
            style={{ marginBottom: 8 }}
          />
          <Input
            label={i18n.t("password")}
            value={password}
            secureTextEntry={true}
            onChangeText={(text) => setPassword(text)}
            style={{ marginBottom: 16 }}
          />
          {loginError == "login" && (
            <Card status="danger" style={{ marginBottom: 16 }}>
              <Text>{i18n.t("msg_login_incorrect")}</Text>
            </Card>
          )}
          {loginError == "other" && (
            <Card status="danger" style={{ marginBottom: 16 }}>
              <Text>{i18n.t("msg_connection_error")}</Text>
            </Card>
          )}
          <Button onPress={() => login()} style={{ marginBottom: 8 }}>
            {i18n.t("log_in")}
          </Button>
          <Button
            appearance="ghost"
            onPress={() => navigation.navigate("ForgottenPassword")}>
            {i18n.t("forgotten_password")}
          </Button>
        </View>
      </KeyboardAwareScrollView>
    </LayoutSafeArea>
  );
}
