import React, { useState } from "react";
import { View, Linking } from "react-native";
import {
  TopNavigation,
  Layout,
  Divider,
  Input,
  Button,
  Text,
  CheckBox,
  Card,
  Icon,
} from "@ui-kitten/components";
import { SafeAreaView } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import i18n from "../i18n";
import renderBackAction from "../utils/renderBackAction";
import ThingsboardService from "../services/ThingsboardService";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function RegisterScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [pairingCode, setPairingCode] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  async function submit() {
    if (!agreed) {
      setError("msg_must_agree");
      return;
    }
    if (
      !email ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError("msg_email_invalid");
      return;
    }
    if (password !== passwordRepeat) {
      setError("msg_password_not_confirmed");
      return;
    }
    if (password.length < 8) {
      setError("msg_password_short");
      return;
    }
    try {
      setLoading(true);
      const response = await ThingsboardService.register({
        pairingCode,
        firstName,
        lastName,
        email,
        password,
      });
      ThingsboardService.saveCredentials(email, password);
      navigation.replace("Home");
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.message == "pairing_code_invalid") {
        setError("msg_pairing_code_invalid");
      } else if (error?.response?.data?.message == "user_exists") {
        setError("msg_email_registered");
      } else {
        setError("other");
      }
    }
  }

  return (
    <Layout style={{ flex: 1, flexDirection: "column" }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          title={i18n.t("register")}
          alignment="center"
          accessoryLeft={() => renderBackAction(navigation)}
        />
        <Divider />

        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <View style={{ flex: 1, flexDirection: "column", padding: 20 }}>
            <Text category="s1" style={{ marginBottom: 12 }}>
              {i18n.t("about_you")}
            </Text>
            <Input
              label={i18n.t("email")}
              value={email}
              onChangeText={(text) => setEmail(text)}
              style={{ marginBottom: 8 }}
            />
            <Input
              label={i18n.t("first_name")}
              value={firstName}
              onChangeText={(text) => setFirstName(text)}
              style={{ marginBottom: 8 }}
            />
            <Input
              label={i18n.t("last_name")}
              value={lastName}
              onChangeText={(text) => setLastName(text)}
              style={{ marginBottom: 8 }}
            />
            <Input
              label={i18n.t("password")}
              value={password}
              onChangeText={(text) => setPassword(text)}
              secureTextEntry={!showPassword}
              /*accessoryRight={(props) => (
                <TouchableOpacity
                  onPress={() => setShowPassword(!showPassword)}>
                  <Icon
                    {...props}
                    name={showPassword ? "eye" : "eye-off"}
                    pack="eva"
                  />
                </TouchableOpacity>
              )}*/
              style={{ marginBottom: 8 }}
            />
            <Input
              label={i18n.t("password_confirm")}
              value={passwordRepeat}
              onChangeText={(text) => setPasswordRepeat(text)}
              secureTextEntry={true}
              style={{ marginBottom: 8 }}
            />
            <Text category="s1" style={{ marginBottom: 8 }}>
              {i18n.t("your_device")}
            </Text>
            <Text category="c1" style={{ marginBottom: 16 }}>
              {i18n.t("msg_pairing_code")}
            </Text>
            <Input
              label={i18n.t("pairing_code")}
              value={pairingCode}
              onChangeText={(text) => setPairingCode(text)}
              style={{ marginBottom: 16 }}
            />
            <CheckBox
              checked={agreed}
              onChange={(nextChecked) => setAgreed(nextChecked)}
              style={{ marginBottom: 16 }}>
              {i18n.t("i_agree_with_terms")}
              <Text
                category="c1"
                style={{ textDecorationLine: "underline", marginLeft: 6 }}
                onPress={() => Linking.openURL(i18n.t("terms_url"))}>
                {i18n.t("i_agree_with_terms_link")}
              </Text>
            </CheckBox>
            <Button
              onPress={() => submit()}
              disabled={loading}
              style={{ marginBottom: 16 }}>
              {i18n.t("submit")}
            </Button>
            {error != null && (
              <Card status="danger">
                <Text>{i18n.t(error)}</Text>
              </Card>
            )}
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Layout>
  );
}
