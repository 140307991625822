import React, { useState } from "react";
import { View } from "react-native";
import {
  TopNavigation,
  Layout,
  Divider,
  Button,
  Text,
  Card,
  Input,
} from "@ui-kitten/components";
import { SafeAreaView } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import i18n from "../i18n";
import renderBackAction from "../utils/renderBackAction";
import ThingsboardService from "../services/ThingsboardService";

export default function ForgottenPasswordScreen({ navigation }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  async function submit() {
    setLoading(true);
    try {
      await ThingsboardService.resetPasswordByEmail(email);
      setStatus("success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response.status == 400) {
        setStatus("error_invalid_user");
      } else {
        setStatus("error_other");
      }
    }
  }

  return (
    <Layout style={{ flex: 1, flexDirection: "column" }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          title={i18n.t("forgotten_password")}
          alignment="center"
          accessoryLeft={() => renderBackAction(navigation)}
        />
        <Divider />
        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <View style={{ flex: 1, padding: 20 }}>
            <Text category="s1" style={{ marginBottom: 12 }}>
              {i18n.t("msg_forgotten_password")}
            </Text>
            <Input
              label={i18n.t("email")}
              value={email}
              onChangeText={(text) => setEmail(text)}
              style={{ marginBottom: 8 }}
            />
            <Button
              onPress={() => submit()}
              disabled={loading}
              style={{ marginBottom: 16 }}>
              {i18n.t("submit")}
            </Button>
            {status == "success" && (
              <Card status="success">
                <Text>{i18n.t("done")}</Text>
              </Card>
            )}
            {status == "error_invalid_user" && (
              <Card status="danger">
                <Text>{i18n.t("msg_login_incorrect")}</Text>
              </Card>
            )}
            {status == "error_other" && (
              <Card status="danger">
                <Text>{i18n.t("msg_connection_error")}</Text>
              </Card>
            )}
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Layout>
  );
}
