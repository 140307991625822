import React, { useEffect, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import {
  TopNavigation,
  Layout,
  Divider,
  Button,
  Text,
  Icon,
  Radio,
  RadioGroup,
  Input,
} from "@ui-kitten/components";
import { SafeAreaView } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import UserSettings from "./UserSettings";
import NightModeSettings from "./NightModeSettings";

import i18n from "../../i18n";
import renderBackAction from "../../utils/renderBackAction";
import ThingsboardService from "../../services/ThingsboardService";

export default function SettingsScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);

  async function loadData() {
    setLoading(true);
    try {
      const devicesRes = await ThingsboardService.getDevices();
      setDevices(devicesRes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <Layout style={{ flex: 1, flexDirection: "column" }}>
        <SafeAreaView>
          <TopNavigation
            title={i18n.t("settings")}
            alignment="center"
            accessoryLeft={() => renderBackAction(navigation)}
          />
          <Divider />
          <ActivityIndicator
            size="large"
            color="#031846"
            style={{ marginTop: 20 }}
          />
        </SafeAreaView>
      </Layout>
    );
  }

  return (
    <Layout style={{ flex: 1, flexDirection: "column" }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          title={i18n.t("settings")}
          alignment="center"
          accessoryLeft={() => renderBackAction(navigation)}
        />
        <Divider />
        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <UserSettings />
          <Divider />
          <Layout level="2">
            <View
              style={{
                flex: 1,
                paddingVertical: 10,
                paddingHorizontal: 20,
                flexDirection: "row",
              }}>
              <Text category="s1">{i18n.t("your_device")}</Text>
            </View>
          </Layout>
          <Divider />
          {devices.map((it) => (
            <>
              <View
                key={it.id}
                style={{
                  flex: 1,
                  padding: 20,
                }}>
                <Text category="h5" style={{ marginBottom: 4 }}>
                  {it.name}
                </Text>
                <Text category="s1" style={{}}>
                  {it.label}
                </Text>
              </View>

              <Divider />
            </>
          ))}
          {devices[0] && <NightModeSettings deviceId={devices[0].id.id} />}
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Layout>
  );
}
